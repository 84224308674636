<template>
    <span :class="classes">{{ balance }}</span>
</template>

<script>
    export default {
        name : 'app-balance-menu',

        computed : {
            classes() {
                return this.$context.user.getBalance(this.$route.params.memberId)
                    ? 'green--text'
                    : 'red--text';
            },

            balance() {
                let balance = this.$context.user.getBalance(this.$route.params.memberId) || 0;
                return this.$vuetify.breakpoint.smAndUp
                    ? `Баланс: ${balance} руб.`
                    : `${balance} руб.`;
            }
        }
    }
</script>