<template>
    <span class="app-returned-menu">
        <a v-if="returnedStaff"   class="mr-2 red--text" href="/admin/#/lids/staff">{{ returnedStaff }}</a>
        <a v-if="returnedTourist" class="mr-2 red--text" href="/admin/#/lids/tourist">{{ returnedTourist }}</a>
    </span>
</template>

<script>
    export default {
        name : 'app-returned-menu',

        computed : {
            returnedStaff() {
                return this.$context.user.returned_staff
                    ? `Возврат КА: ${this.$context.user.returned_staff}`
                    : '';
            },
            returnedTourist() {
                return this.$context.user.returned_tourist
                    ? `Возврат ТА: ${this.$context.user.returned_tourist}`
                    : '';
            }
        }
    }
</script>