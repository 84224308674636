<template>
    <v-layout row wrap class="app-lid-dialog">
        <v-flex xs12 md9>
            <v-layout row wrap>
                <v-flex xs12>
                    <app-lid-client-staff    v-if="lidType.code === 'staff'" :box="box" :model="model.item" :show-client="showClient" :allow-edit="allowEdit"/>
                    <app-lid-client-tourist  v-else                          :box="box" :model="model.item" :show-client="showClient" :allow-edit="allowEdit"/>
                </v-flex>
                <v-flex xs12>
                    <app-lid-data-staff   v-if="lidType.code === 'staff'" :box="box" :model="model.item" :show-client="showClient" :allow-edit="allowEdit"/>
                    <app-lid-data-tourist v-else                          :box="box" :model="model.item" :show-client="showClient" :allow-edit="allowEdit"/>
                </v-flex>
                <v-flex xs12>
                    <f-panel title="Стоимость">
                        <v-layout row flow>
                            <v-flex xs12 md3 v-if="allowEdit">
                                <v-text-field :box="box"
                                              label="Ваша"
                                              suffix="руб."
                                              :disabled="!allowEdit"
                                              v-model="model.item.price"
                                />
                            </v-flex>

                            <v-flex xs12 md3 v-if="allowEdit">
                                <ax-date-field label="Дата" time-start v-model="model.item.price_ts" />
                            </v-flex>

                            <v-flex xs12 md3 v-if="allowEdit">
                                <v-text-field :box="box"
                                              label="После даты"
                                              suffix="руб."
                                              :disabled="!allowEdit"
                                              v-model="model.item.price_after"
                                />
                            </v-flex>


                            <v-flex xs12 :md3="allowEdit" >
                                <v-text-field :box="box"
                                              label="На бирже"
                                              suffix="руб."
                                              disabled
                                              v-model="model.item.price_total"
                                />
                            </v-flex>
                        </v-layout>
                    </f-panel>
                </v-flex>
                <v-flex xs12>
                    <f-panel title="Статусы">
                        <v-layout row wrap>
                            <v-flex xs12 md4>
                                <v-textarea :box="box"box
                                            v-model="create"
                                            label="Создана"
                                            disabled
                                            auto-grow
                                            rows="1"
                                />
                            </v-flex>
                            <v-flex xs12 md4>
                                <v-textarea :box="box"
                                            v-model="moderate"
                                            label="Проверена"
                                            disabled
                                            auto-grow
                                            rows="1"
                                />
                            </v-flex>
                            <v-flex v-if="showPurchase" xs12 md4>
                                <v-textarea :box="box"
                                            v-model="purchase"
                                            label="Куплена"
                                            disabled
                                            auto-grow
                                            rows="1"
                                />
                            </v-flex>
                            <v-flex v-else xs12 md4>
                            <v-textarea :box="box"
                                        v-model="sold"
                                        label="Продана"
                                        disabled
                                        auto-grow
                                        rows="1"
                            />
                        </v-flex>
                        </v-layout>
                    </f-panel>
                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex xs12 md3>
            <f-panel title="Комментарии">
                <v-textarea :box="box"
                            v-if="newCommentTrigger"
                            v-model="newComment"
                            auto-grow
                            simple
                            rows="1"
                            hide-details
                            append-icon="mdi-send"
                            class="app-lid-dialog__new-comment"
                            @click:append="sendComment"
                            @keyup.enter.ctrl="sendComment"
                            ref="newComment"
                />
                <v-card v-for="comment in model.item.comments" :key="comment.ts" class="app-lid-dialog__comment" hover>
                    <div>
                        <span class="app-lid-dialog__comment__user text--lighten-1 grey--text">{{ comment.user_name }}</span>
                        <span class="app-lid-dialog__comment__ts text--lighten-1 grey--text">{{ $t(new Date(parseInt(comment.ts))) }}</span>
                    </div>
                    <pre>{{ comment.comment }}</pre>
                </v-card>
            </f-panel>
        </v-flex>
    </v-layout>
</template>

<script>
    import AppLidClientStaff from './staff/lid-client-staff';
    import AppLidClientTourist from './tourist/lid-client-tourist';
    import AppLidDataStaff from './staff/lid-data-staff';
    import AppLidDataTourist from './tourist/lid-data-tourist';

    export default {
        name: 'app-lid-dialog',

        components: {
            AppLidDataTourist,
            AppLidDataStaff,
            AppLidClientTourist,
            AppLidClientStaff
        },

        props : {
            model : {}
        },

        data() {
            return {
                newComment         : '',
                newCommentTrigger : true,
                box : true
            }
        },

        computed : {
            lidType() {
                return this.model.lidType;
            },

            create() {
                return (this.model && this.model.item.create_user)
                    ? `${this.model.item.create_user.name}\n${this.$t(new Date(parseInt(this.model.item.create_ts)))}`
                    : '---\n---'
            },
            moderate() {
                return (this.model && this.model.item.moderate_user)
                    ? `${this.model.item.moderate_user.name}\n${this.$t(new Date(parseInt(this.model.item.moderate_ts)))}`
                    : '---\n---'
            },
            purchase() {
                return (this.model && this.model.item.purchase_user)
                    ? `${this.model.item.purchase_user.name}\n${this.$t(new Date(parseInt(this.model.item.purchase_ts)))}`
                    : '---\n---'
            },
            sold() {
                return (this.model && this.model.item.purchase_ts)
                    ? `---\n${this.$t(new Date(parseInt(this.model.item.purchase_ts)))}`
                    : '---\n---'
            },

            allowEdit() {
                switch (this.model.item.state) {
                    case 'editing':
                        return true;
                    default:
                        return false;
                }
            },
            showPurchase() {
                return this.model.item.state === 'purchased' && this.model.item.purchase_owner_id === this.$route.params.memberId;
            },
            allowCreator() {
                return this.model.item.state === 'editing';
            },
            allowModerator() {
                return this.model.item.state === 'checking' && !!this.$context.user.isInRole('moderator', this.$route.params.memberId);
            },
            showClient() {
                return !!this.model.item.client_id;
            }
        },

        methods : {
            sendComment() {
                let comment = {
                    user_id   : this.$context.user.id,
                    user_name : this.$context.user.name,
                    ts        : new Date().getTime(),
                    comment   : this.newComment
                };
                this.model.item.comments.unshift(comment);
                if (this.model.item.rest) {
                    this.$http.post(this.model.item.rest, comment);
                }
                this.newComment = '';
                this.newCommentTrigger = false;
                this.$nextTick(() => {
                    this.newCommentTrigger = true;
                    this.$nextTick(() => this.$refs.newComment.focus());
                });
            }
        },

        sockets : {
            events: {
                async 'comments:add'({ message, lid_id }) {
                    let lidId = this.$route.query.id;
                    if (lid_id === lidId) {
                        let rest = `${this.model.rest}/${lidId}/comments`;
                        let res = await this.$http.get(rest);
                        this.model.item.comments = res.data;
                    }
                }
            }
        }
    }
</script>

<style lang="less">
    .fude-dialog.auto-width.xs {
        .app-lid-dialog {
            min-width: 220px;
        }
    }
    .fude-dialog.auto-width.sm {
        .app-lid-dialog {

        }
    }
    .fude-dialog.auto-width.md {
        .app-lid-dialog {
            min-width: 900px;
        }
    }
    .fude-dialog.auto-width.lg {
        .app-lid-dialog {
            max-width: 1200px;
        }
    }
    .fude-dialog.auto-width.xl {
        .app-lid-dialog {
            max-width: 1200px;
        }
    }

    .app-lid-dialog {
        margin: 0 !important;
        .v-text-field__details {
            display: none;
        }
        .app-lid-dialog__new-comment {
            .v-input__append-inner {
                align-self: flex-end;
                margin-top: 0;
                margin-bottom: 6px;
                z-index: 10;
            }
            .v-input__slot {
                min-height: 0!important;
            }
            textarea {
                margin-top: 0!important;
            }
        }
        .app-lid-dialog__comment {
            padding: 10px;
            margin-top: 10px;
            background-color: #424242;
            .app-lid-dialog__comment__ts {
                font-size: 12px;
            }
            .app-lid-dialog__comment__user {
                font-size: 12px;
            }
            pre {
                word-break: break-word;
                white-space: pre-wrap;
            }
        }
    }
</style>