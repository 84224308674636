import MainMenu from './main-menu';
import BalanceMenu from './balance-menu';
import ReturnedMenu from './returned-menu';
import Drawer from './drawer';
import Content from './content';
import Footer from './footer';
import DateField from './ax-date-field';
import LidList from './common/lid-list';

export default {
    MainMenu,
    BalanceMenu,
    ReturnedMenu,
    Drawer,
    Content,
    Footer,
    DateField,
    LidList
}