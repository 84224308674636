<template>
    <v-menu content-class="app-main-menu" offset-y left>
        <fude-btn-resizable
                slot="activator"
                :title="userName"
                color="primary"
                icon="mdi-account"
        />
        <v-list>
            <template v-for="item in items">
                <v-divider v-if="item.divider"/>
                <v-list-tile v-else-if="item.click" @click="item.click">
                    <v-list-tile-title class="text-truncate">{{ item.name }}</v-list-tile-title>
                </v-list-tile>
                <v-list-tile v-else v-bind:href="item.href">
                    <v-list-tile-title class="text-truncate">{{ item.name }}</v-list-tile-title>
                </v-list-tile>
            </template>
        </v-list>
    </v-menu>

</template>

<script>
    export default {
        name: 'app-main-menu',

        computed : {
            userName() {
                return this.$context.user.name;
            },

            items() {
                let result = [];
                let roles = this.$context.user.roles.filter((r) => !!r.member_id);
                if (roles.length > 1) {
                    result.push({ name : 'Выбор участника биржи', click: () => this.$navigate('/') });
                } else {
                    result.push({ name : 'Биржа', click: () => this.$navigate('/') },);
                }
                if (this.$context.user.isAdmin) {
                    result.push({ name : 'Администрирование', click: () => this.$navigate('/admin') });
                }
                result.push(
                    { divider : true },
                    { name : 'Выход', click: () => this.logout() }
                );
                return result;
            }
        },

        methods : {
            logout : function() {
                this.$http.post('/api/v1/users/logout').then(() => {
                    this.$navigate('/login/#/');
                }).catch((error) => {
                    console.log(error);
                    console.log(error.response);
                });
            }
        }
    }
</script>

<style lang="less">
    .app-main-menu {
        .v-list__tile {
            height: 36px;
        }
        .v-list__tile__title {
            max-width: 200px;
        }
    }
</style>