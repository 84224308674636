<template functional>
    <v-content class="app-content" :class="props.center ? 'app-content--center' : ''">
        <v-container fluid class="app-content__container">
            <v-slide-x-transition appear mode="out-in">
                <router-view></router-view>
            </v-slide-x-transition>
        </v-container>
    </v-content>
</template>

<script>
    export default {
        name: 'app-content',

        props : {
            center : {
                type    : Boolean,
                default : false
            }
        }
    }
</script>

<style lang="less">
    .app-content {
        &&.app-content--center {
            .app-content__container {
                display: flex;
                margin: auto;
                height: 100%;
            }
        }
    }
</style>