<template functional>
    <v-footer fixed app class="pa-3">
        <v-spacer></v-spacer>
        <div>JOB24 EXCHANGE &copy; {{ new Date().getFullYear() }}</div>
    </v-footer>
</template>

<script>
    export default {
        name: 'app-footer'
    }
</script>