<template>
    <v-card class="app-admin-member-list">
        <fude-table :settings="settings" ref="table">

            <div slot="sidv" slot-scope="{ item }">
                <v-chip class="dense">{{ item.sid }}</v-chip>
            </div>

            <div slot="member_name" slot-scope="{ item }">
                <v-chip class="dense" :color="$route.params.memberId === item.member_id ? 'green' : ''">{{ item.member_name }}</v-chip>
            </div>

            <div slot="purchase_owner_name" slot-scope="{ item }">
                <v-chip class="dense" :color="$route.params.memberId === item.purchase_owner_id ? 'green' : ''">{{ item.purchase_owner_name }}</v-chip>
            </div>

            <div slot="vacancy" slot-scope="{ item }">
                <h3 class="green--text">{{ item.vacancy }}</h3>
                <h4><i>{{ item.category }}</i></h4>
            </div>

            <div v-if="item.create_ts" slot="create_ts" slot-scope="{ item }">
                <div>{{ $t(new Date(parseInt(item.create_ts))) }}</div>
                <div class="primary--text1">{{ item.create_user.name }}</div>
            </div>
            <div v-if="item.moderate_ts" slot="moderate_ts" slot-scope="{ item }">
                <div>{{ $t(new Date(parseInt(item.moderate_ts))) }}</div>
                <div class="primary--text1">{{ item.moderate_user.name }}</div>
            </div>
            <div v-if="item.exchange_ts" slot="exchange_ts" slot-scope="{ item }">
                <div>{{ $t(new Date(parseInt(item.exchange_ts))) }}</div>
                <!--<div class="primary&#45;&#45;text1">{{ item.moderate_user.name }}</div>-->
            </div>
            <div v-if="item.purchase_ts" slot="purchase_ts" slot-scope="{ item }">
                <div>{{ $t(new Date(parseInt(item.purchase_ts))) }}</div>
                <div class="primary--text1">{{ item.purchase_user.name }}</div>
            </div>
            <div v-if="item.purchase_ts" slot="sold_ts" slot-scope="{ item }">
                <div>{{ $t(new Date(parseInt(item.purchase_ts))) }}</div>
            </div>

            <div slot="price_total" slot-scope="{ item }">
                <v-btn v-if="item.price_total" xsmal color="green" style="margin: 0">
                    <b>{{ item.price_total }}</b>
                </v-btn>
            </div>

        </fude-table>
    </v-card>
</template>

<script>
    import LidDialog from './lid-dialog';

    export default {
        name: 'app-lid-list',

        props : {
            title       : {},
            state       : {},
            hideHeaders : {},
            rest        : {},
            lidTypeCode : {},
            sort        : {}
        },

        sockets : {
            events: {
                'lids:update'({ message }) {
                    this.$eventBus.$emit('LID_COUNT_CHANGED');
                    this.$context.$remoteData.reload();
                    this.$refs.table.reload();
                    this.showToast(message);
                },
                'lids:remove'({ message }) {
                    this.$eventBus.$emit('LID_COUNT_CHANGED');
                    this.$refs.table.reload();
                    this.showToast(message);
                },
                'comments:add'({ message }) {
                    this.showToast(message);
                }
            }
        },

        computed : {
            settings() {
                let self = this;
                let memberId = this.$route.params.memberId;
                let lidType  = this.lidTypeCode
                    ? { code : this.lidTypeCode }
                    : this.$context.user.getLidType(memberId);

                let rest = this.rest || `/api/v1/members/${memberId}/lids/${this.state}`;

                let headers = {
                    sidv                : { text : { text : 'ID'              } },

                    client_name         : { text : { text : 'Клиент'          } },

                    vacancy             : { text : { text : 'Вакансия'        }, lid_type_code : 'staff' },
                    place_count         : { text : { text : 'Количество'      }, lid_type_code : 'staff' },
                    city                : { text : { text : 'Город'           }, lid_type_code : 'staff' },

                    member_name         : { text : { text : 'Владелец'        }  },

                    country             : { text : { text : 'Страна'          }, lid_type_code : 'tourist' },
                    start_date          : { text : { text : 'Дата вылета'     }, lid_type_code : 'tourist' },
                    start_city          : { text : { text : 'Город вылета'    }, lid_type_code : 'tourist' },

                    create_ts           : { text : { text : 'Создана'         }, default : 'desc' },
                    moderate_ts         : { text : { text : 'Проверена'       } },
                    exchange_ts         : { text : { text : 'На бирже'        } },
                    purchase_ts         : { text : { text : 'Куплена'         } },
                    sold_ts             : { text : { text : 'Продана'         }, value : 'purchase_ts' },
                    purchase_owner_name : { text : { text : 'Покупатель'      } },
                    price_total         : { text : { text : 'Цена (руб.)'     } }

                };

                Object.keys(headers).forEach((name) => {
                    let header = headers[name];
                    if (header.lid_type_code && header.lid_type_code !== lidType.code) {
                        delete headers[name];
                    }
                });

                if (self.sort) {
                    delete headers.create_ts;
                    headers[self.sort.field].default = self.sort.value;
                }

                if (this.hideHeaders) {
                    this.hideHeaders.forEach((header) => delete headers[header]);
                }

                let actions = {
                    dialog : LidDialog,

                    async model(item) {
                        if (!item.id) {
                            item.state = 'editing';
                            item.client_id = 'new_id';
                            item.comments = [];
                        } else {
                            item = self.$clone(item);
                            item.rest = `${rest}/${item.id}/comments`;
                            let res = await self.$http.get(item.rest);
                            item.comments = res.data || [];
                            if (item.price_ts) {
                                item.price_ts = new Date(parseInt(item.price_ts));
                            }
                        }
                        if (!item.client_employee_count) {
                            item.client_employee_count = 0;
                        }
                        return {
                            item,
                            rest,
                            lidType
                        };
                    },

                    dto({ item }) {
                        if (item.client_id === 'new_id') {
                            delete item.client_id;
                        }
                        if (item.price_ts && typeof item.price_ts.getTime === 'function') {
                            item.price_ts = item.price_ts.getTime();
                        }
                        return item;
                    },

                    custom : [{
                        result: 'COPY',
                        close : false,
                        reload : false,
                        color : 'green',
                        name: 'Скопировать ссылку',
                        top : true,
                        handle: async () => {
                            self.$core.tools.copyToClipboard(window.location.href);
                            self.$dialog.$info('Ссылка скопирована.');
                        }
                    }]
                };

                function countChanged() {
                    self.$eventBus.$emit('LID_COUNT_CHANGED');
                }

                function setState(state, name, confirm) {
                    return {
                        result  : state,
                        name    : name,
                        left    : true,
                        color   : 'primary',
                        confirm : () => confirm || `${name}?`,
                        handle  : async (item) => {
                            item.state = state;
                            if (item.id) {
                                await self.$http.put(`${rest}/${item.id}`, item);
                            } else {
                                await self.$http.post(`${rest}`, item);
                            }
                            countChanged();
                        }
                    };
                }
                function save() {
                    return {
                        result: 'SAVE',
                        name: 'Сохранить',
                        handle: async (item) => {
                            if (item.id) {
                                await self.$http.put(`${rest}/${item.id}`, item);
                            } else {
                                await self.$http.post(`${rest}`, item);
                            }
                            countChanged();
                        }
                    };
                }
                function cancel() {
                    return {
                        result: 'CANCEL',
                        name: 'Отмена'
                    };
                }
                function ok() {
                    return {
                        result: 'CANCEL',
                        name: 'OK'
                    };
                }

                switch(this.state) {
                    case 'editing':
                        if (this.$context.user.isInRole('user', memberId)) {
                            actions.add = {
                                title: () => 'Создание заявки',
                                actions: (item) => [
                                    setState('checking', 'Отправить на модерацию'),
                                    save(),
                                    cancel()
                                ]
                            };
                            actions.edit = {
                                title: () => 'Редактирование заявки',
                                actions: (item) => [
                                    setState('checking', 'Отправить на модерацию'),
                                    save(),
                                    cancel()
                                ]
                            };
                            actions.remove = {
                                title: (item) => `Удалить заявку '${item.vacancy}'?`
                            };
                        }
                        break;
                    case 'checking' :
                        actions.edit = {
                            title : () => 'Модерация заявки',
                            actions : (item) => this.$context.user.isInRole('moderator', memberId) ? [
                                setState('exchanged', 'Отправить на биржу'),
                                setState('editing', 'Отправить на доработку'),
                                cancel()
                            ] : [
                                ok()
                            ]
                        };
                        break;
                    case 'exchanged' :
                        actions.edit = {
                            title : () => 'Заявка на бирже',
                            actions : ({ item }) => (item.member_id === memberId && (this.$context.user.isInRole('moderator', memberId) || this.$context.user.id === item.create_user_id)) ? [
                                setState('rejected', 'Снять с биржи'),
                                cancel()
                            ] : [
                                ok()
                            ]
                        };
                        break;
                    case 'rejected' :
                        actions.edit = {
                            title : () => 'Просмотр заявки',
                            actions : ({ item }) => (this.$context.user.isInRole('moderator', memberId) || this.$context.user.id === item.create_user_id) ? [
                                setState('editing', 'Редактировать'),
                                cancel()
                            ] : [
                                ok()
                            ]
                        };
                        break;
                    case 'purchased' :
                        actions.edit = {
                            title : () => 'Просмотр заявки',
                            actions : ({ item }) => {
                                return [
                                    ...(this.$context.user.isInRole('buyer', memberId) && item.member_id !== memberId ? [{
                                        result  : 'RETURN',
                                        name    : 'Отменить покупку',
                                        left    : true,
                                        color   : 'primary',
                                        confirm : () => `Отменить покупку заявки '${item.display_name}'?`,
                                        handle  : async (item) => {
                                            await this.$http.put(`${rest}/${item.id}`, {
                                                id                : item.id,
                                                purchase_owner_id : memberId,
                                                state             : 'returned'
                                            });
                                            await this.$context.$remoteData.reload();
                                            countChanged();
                                        }
                                    }] : []),{
                                        result : 'CANCEL',
                                        name   : 'OK'
                                    }];
                            }
                        };
                        break;
                    case 'returned' :
                        actions.edit = {
                            title : () => 'Просмотр заявки',
                            actions : ({ item }) => {
                                return [
                                    ...(this.$context.user.isAdmin ? [{
                                        result  : 'RETURN',
                                        name    : 'Вернуть деньги',
                                        left    : true,
                                        color   : 'primary',
                                        confirm : () => `Вернуть деньги за заявку '${item.display_name}'?`,
                                        handle  : async (item) => {
                                            await this.$http.put(`${rest}/${item.id}`, {
                                                id                : item.id,
                                                member_id         : item.member_id,
                                                purchase_owner_id : item.purchase_owner_id,
                                                state             : 'rejected'
                                            });
                                            await this.$context.$remoteData.reload();
                                            countChanged();
                                        }
                                    }] : []),{
                                        result : 'CANCEL',
                                        name   : 'OK'
                                    }];
                            }
                        };
                        break;
                    case 'sold' :
                        actions.edit = {
                            title : () => 'Просмотр заявки',
                            actions : () => [{
                                result : 'CANCEL',
                                name   : 'OK'
                            }]
                        };
                        break;
                    case 'exchange':
                        actions.edit = {
                            title : () => 'Просмотр заявки',
                            actions : ({ item }) => {
                                return [
                                    ...(this.$context.user.isInRole('buyer', memberId) && item.member_id !== memberId ? [{
                                        result  : 'BUY',
                                        name    : 'Купить',
                                        left    : true,
                                        color   : 'primary',
                                        confirm : () => `Купить заявку за ${item.price_total} руб.?`,
                                        handle  : async (item) => {
                                            if (item.price_total > this.$context.user.getBalance(memberId)) {
                                                throw Error(`Недостаточно средств на балансе! <a href="/#/${memberId}/balance" class="ml-2 indigo--text font-weight-bold">Проверить баланс</a>`);
                                            }
                                            await this.$http.put(`${rest}/${item.id}`, {
                                                id                : item.id,
                                                purchase_owner_id : memberId,
                                                price             : item.price,
                                                state             : 'purchased'
                                            });
                                            await this.$context.$remoteData.reload();
                                            countChanged();
                                        }
                                    }] : []),
                                    ...((item.member_id === memberId && (this.$context.user.isInRole('moderator', memberId) || this.$context.user.id === item.create_user_id)) ? [
                                        setState('rejected', 'Снять с биржи'),
                                    ] : []),
                                    ok()
                                ]
                            }
                        };
                        break;
                }

                return {
                    title  : { text: this.title },
                    search : (params) => {
                        let lidType  = this.lidTypeCode
                            ? { code : this.lidTypeCode }
                            : this.$context.user.getLidType(memberId);
                        params.lid_type_id   = lidType.id;
                        params.lid_type_code = lidType.code;
                        return params;
                    },
                    pagination : true,
                    linkField  : 'id',
                    rowsPerPage : 25,

                    openFirst : false,

                    headers,
                    rest,
                    actions
                }
            }
        },

        methods : {
            showToast(message) {
                this.$eventBus.$emit('SHOW_TOAST', message);
            }
        },


        mounted() {
            this.$eventBus.$emit('LID_COUNT_CHANGED');
        },

        beforeDestroy() {

        }
    }
</script>