<template>
    <f-panel title="Вакансия">
        <v-layout row wrap>
            <v-flex xs12 md8>
                <v-text-field :box="box"
                              label="Название"
                              :disabled="!allowEdit"
                              v-model="model.vacancy"
                />
            </v-flex>
            <v-flex xs12 md4>
                <v-text-field :box="box"
                              label="Количество вакансий"
                              :disabled="!allowEdit"
                              v-model="model.place_count"
                />
            </v-flex>
            <v-flex xs12 md8>
                <v-select :box="box"
                          label="Сфера"
                          :disabled="!allowEdit"
                          :items="categories"
                          v-model="model.category"
                          :menu-props="{ closeOnContentClick : true, closeOnClick : false, offsetY : true }"
                />
            </v-flex>
            <v-flex xs12 md4>
                <v-text-field :box="box"
                              label="Город"
                              :disabled="!allowEdit"
                              v-model="model.city"
                />
            </v-flex>
            <v-flex xs12>
                <v-textarea :box="box"
                            label="Функции"
                            :disabled="!allowEdit"
                            v-model="model.functions"
                            auto-grow
                            rows="1"
                />
            </v-flex>
        </v-layout>
    </f-panel>
</template>

<script>
    export default {
        name: 'app-lid-data-staff',

        data() {
            return {
                categories : [
                    'Автомобильный бизнес',
                    'Административный персонал',
                    'Банки, инвестиции, лизинг',
                    'Безопасность',
                    'Бухгалтерия, управленческий учет, финансы предприятия',
                    'Высший менеджмент',
                    'Государственная служба, некоммерческие организации',
                    'Добыча сырья',
                    'Домашний персонал',
                    'Закупки',
                    'Инсталляция и сервис',
                    'Информационные технологии, интернет, телеком',
                    'Искусство, развлечения, масс-медиа',
                    'Консультирование',
                    'Маркетинг, реклама, PR',
                    'Медицина, фармацевтика',
                    'Наука, образование',
                    'Начало карьеры, студенты',
                    'Продажи',
                    'Производство',
                    'Рабочий персонал',
                    'Спортивные клубы, фитнес, салоны красоты',
                    'Страхование',
                    'Строительство, недвижимость',
                    'Транспорт, логистика',
                    'Туризм, гостиницы, рестораны',
                    'Управление персоналом, тренинги',
                    'Юристы'
                ]
            }
        },

        props : {
            model      : {},
            showClient : {},
            allowEdit  : {},
            box        : {}
        }
    }
</script>