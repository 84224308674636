<template>
    <v-navigation-drawer
            v-if="items"
            fixed
            clipped
            floating
            app
            v-model="show"
            class="app-drawer"
    >
        <v-list dense>
            <template v-for="(item, i) in items">
                <v-list-group v-if="item.items && item.items.length" :key="i" no-action value="true">
                    <v-list-tile slot="activator" :to="item.to">
                        <v-list-tile-action>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-tile-action>
                        <v-list-tile-content>
                            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                        </v-list-tile-content>
                        <v-list-tile-avatar v-if="item.count != null">
                            <v-chip color="primary" class="dense white--text">{{ item.count }}</v-chip>
                        </v-list-tile-avatar>
                    </v-list-tile>
                    <template v-for="(subItem, j) in item.items">
                        <v-divider v-if="subItem.divider" :key="`${i}_${j}`"/>
                        <v-list-tile v-else :key="`${i}_${j}`" :to="subItem.to">
                            <v-list-tile-content>
                                <v-list-tile-title>{{ subItem.title }}</v-list-tile-title>
                            </v-list-tile-content>
                            <v-list-tile-action>
                                <v-icon>{{ subItem.icon }}</v-icon>
                            </v-list-tile-action>
                            <v-list-tile-avatar v-if="subItem.count != null">
                                <v-chip color="primary" class="dense white--text">{{ subItem.count }}</v-chip>
                            </v-list-tile-avatar>
                        </v-list-tile>
                    </template>
                </v-list-group>
                <v-divider v-else-if="item.divider" :key="i"/>
                <v-list-tile v-else :key="item.title" :to="item.to" >
                    <v-list-tile-action>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                        <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-avatar v-if="item.count != null">
                        <v-chip color="primary" class="dense white--text">{{ item.count }}</v-chip>
                    </v-list-tile-avatar>
                </v-list-tile>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    export default {
        name : 'app-drawer',

        props : {
            items : { },
            value : { }
        },

        data() {
            return {
                show : true
            }
        },

        watch : {
            value() {
                this.show = this.value;
            },
            show() {
                this.$emit('input', this.show);
            }
        }

    }
</script>

<style lang="less">
    .app-drawer {
        .v-avatar {
            justify-content: flex-end;
        }
    }
</style>