<template>
    <f-panel title="Компания клиента (работодатель)">
        <v-layout row wrap>
            <v-flex xs12 md2 >
                <v-text-field :box="box"
                              label="ID"
                              disabled
                              v-model="model.sid"
                />
            </v-flex>
            <v-flex xs12 md7 v-if="showClient">
                <v-text-field :box="box"
                              label="Название"
                              :disabled="!allowEdit"
                              v-model="model.client_name"
                />
            </v-flex>
            <v-flex v-else xs7>
                <v-text-field :box="box"
                              placeholder="Информация откроется после покупки"
                              disabled
                />
            </v-flex>
            <v-flex xs12 md3>
                <v-select
                        label="Численность"
                        box
                        :disabled="!allowEdit"
                        v-model="model.client_employee_count"
                        :items="employeeCounts"
                        :menu-props="{ closeOnClick : false, offsetY : true }"
                />
            </v-flex>
            <v-flex xs12 md4 v-if="showClient">
                <v-text-field :box="box"
                              label="Контактное лицо"
                              :disabled="!allowEdit"
                              v-model="model.client_contact_name"
                />
            </v-flex>
            <v-flex xs12 md2 v-if="showClient">
                <v-text-field :box="box"
                              label="Должность"
                              :disabled="!allowEdit"
                              v-model="model.client_contact_position"
                />
            </v-flex>
            <v-flex xs12 md6 v-if="showClient">
                <v-text-field :box="box"
                              label="Контакты"
                              :disabled="!allowEdit"
                              v-model="model.client_contact_value"
                />
            </v-flex>
        </v-layout>
    </f-panel>
</template>

<script>
    export default {
        name: 'app-lid-client-staff',

        data() {
            return {
                employeeCounts : [
                    { text : 'Не определено', value : 0   },
                    { text : '1-10',          value : 1   },
                    { text : '11-50',         value : 11  },
                    { text : '51-200',        value : 51  },
                    { text : 'Более 201',     value : 201 }
                ]
            }
        },

        props : {
            model      : {},
            showClient : {},
            allowEdit  : {},
            box        : {}
        }
    }
</script>