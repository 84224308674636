<template>
    <f-panel title="Клиент">
        <v-layout v-if="showClient" row wrap>
            <v-flex xs12 md2 >
                <v-text-field :box="box"
                              label="ID"
                              disabled
                              v-model="model.sid"
                />
            </v-flex>
            <v-flex xs12 md3>
                <v-text-field :box="box"
                              label="ФИО"
                              :disabled="!allowEdit"
                              v-model="model.client_name"
                />
            </v-flex>
            <v-flex xs12 md3>
                <v-text-field :box="box"
                              label="Телефон"
                              :disabled="!allowEdit"
                              v-model="model.client_phone"
                />
            </v-flex>
            <v-flex xs12 md4>
                <v-text-field :box="box"
                              label="Электронная почта"
                              :disabled="!allowEdit"
                              v-model="model.client_email"
                />
            </v-flex>
        </v-layout>
        <v-layout v-else row wrap>
            <v-flex xs12 md2>
                <v-text-field :box="box"
                              label="ID"
                              disabled
                              v-model="model.sid"
                />
            </v-flex>
            <v-flex xs12 md10>
                <v-text-field :box="box"
                              placeholder="Информация откроется после покупки"
                              disabled
                />
            </v-flex>
        </v-layout>
    </f-panel>
</template>

<script>
    export default {
        name: 'app-lid-client-tourist',

        props : {
            model      : {},
            showClient : {},
            allowEdit  : {},
            box        : {}
        }
    }
</script>