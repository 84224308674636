<template>
    <v-menu
            lazy
            :close-on-content-click="false"
            v-model="menu"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-right="40"
            max-width="290px"
            min-width="290px"
            class="ax-date-field"
    >
        <v-text-field
                slot="activator"
                :label="label"
                v-model="dateFormatted"
                readonly
                box

        ></v-text-field>
        <v-date-picker v-model="date" @input="dateFormatted = formatDate($event)" no-title scrollable actions locale="ru-ru">

        </v-date-picker>
    </v-menu>
</template>

<script>
    import moment from 'moment';

    moment.locale('ru-ru');

    export default {
        name : "ax-date-field",

        props : {
            'icon' : {
                type : String,
                default : 'mdi-event'
            },
            'label' : String,
            'time-start' : Boolean,
            'time-end' : Boolean,
            'value' : Date
        },

        data : function () {
            return {
                date: null,
                dateFormatted: null,
                menu: false
            }
        },

        watch : {
            date : function() {
                this.menu = false;
                let value = this.date ? moment(this.date, 'YYYY-MM-DD') : null;
                if (value) {
                    if (this.timeStart) {
                        value.set({ hour : 0, minute: 0, second : 0, millisecond : 0 });
                    } else if (this.timeEnd) {
                        value.set({ hour : 23, minute: 59, second : 59, millisecond : 999 });
                    }
                    value = value.toDate();
                } else {
                    this.dateFormatted = null;
                }
                this.$emit('input', value);
            },

            value : function() {
                if (!this.value) {
                    this.date = null;
                    this.dateFormatted = null;
                }
            }
        },

        methods :  {
            formatDate (date) {
                if (!date) {
                    return null
                }
                date = moment(date, 'YYYY-MM-DD');
                return date.format('DD MMMM YYYY');
            }
        },

        mounted : function() {
            if (this.value) {
                this.date = moment(this.value).format('YYYY-MM-DD');
                this.dateFormatted = moment(this.value).format('DD MMMM YYYY');
            }
        }
    }
</script>

<style lang="less">
    .ax-date-field {
        display: inline-flex;
    }
</style>