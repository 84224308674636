class User {
    constructor(data) {
        this.id = data.id;
        this.name = data.name || data.email;
        this.email = data.email;
        this.roles = data.roles || [];
        this._isAdmin = !!this.roles.find((r) => r.role_code === 'admin');
        this.returned_staff   = data.returned_staff;
        this.returned_tourist = data.returned_tourist;
    }

    isInRole(role, memberId) {
        return !!this.roles.find((r) => r.role_code === role && r.member_id === memberId);
    }

    getBalance(memberId) {
        let role = this.roles.find((r) => r.member_id === memberId);
        return (role && role.member_balance) || null;
    }
    getLidType(memberId) {
        let role = this.roles.find((r) => r.member_id === memberId);
        if (!role) {
            return null;
        }
        return {
            id   : role.member_lid_type_id,
            code : role.member_lid_type_code,
            name : role.member_lid_type_name
        };
    }
    getBalanceInfo(memberId) {
        let role = this.roles.find((r) => r.member_id === memberId);
        return (role && role.member_balance_info) || '';
    }
    setBalance(memberId, balance) {
        this.roles
            .filter((r) => r.member_id === memberId)
            .forEach((r) => r.member_balance = balance);
    }

    get isAdmin() {
        return this._isAdmin;
    }
    get userMemberId() {
        throw new Error('userMemberId deprecated');
    }
    get moderatorMemberId() {
        throw new Error('moderatorMemberId deprecated');
    }
    get memberId() {
        throw new Error('memberId deprecated');
    }
    get balance() {
        throw new Error('balance deprecated');
    }
}

export default User;