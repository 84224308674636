import User from './user';

export default {
    data() {
        return {
            title       : 'JOB24',
            subTitle    : 'Биржа'
        }
    },

    remoteData : {
        async user() {
            let res = await this.$http.get('/api/v1/users/current?' + new Date().getTime());
            return new User(res.data);
        }
    }
}