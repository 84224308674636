<template>
    <f-panel title="Описание">
        <v-layout row wrap>
            <v-flex xs12 md4>
                <v-text-field :box="box"
                              label="Дата поездки"
                              :disabled="!allowEdit"
                              v-model="model.start_date"
                />
            </v-flex>
            <v-flex xs12 md3>
                <v-text-field :box="box"
                              label="Город вылета"
                              :disabled="!allowEdit"
                              v-model="model.start_city"
                />
            </v-flex>
            <v-flex xs12 md5>
                <v-text-field :box="box"
                              label="Кол-во человек"
                              :disabled="!allowEdit"
                              v-model="model.persons"
                />
            </v-flex>
            <v-flex xs12 md5>
                <v-autocomplete :box="box"
                                label="Страна"
                                :disabled="!allowEdit"
                                :items="countries"
                                v-model="model.country"
                                :menu-props="{ closeOnContentClick : true, closeOnClick : false, offsetY : true }"
                />
            </v-flex>
            <v-flex xs12 md7>
                <v-text-field v-if="showClient" :box="box"
                              label="Бюджет на отдых"
                              :disabled="!allowEdit"
                              v-model="model.budget"
                />
                <v-text-field v-else :box="box"
                              label="Бюджет на отдых"
                              disabled
                              v-model="hidden"
                />
            </v-flex>
            <v-flex xs12>
                <v-text-field :box="box"
                              label="Пожелания к отелю"
                              :disabled="!allowEdit"
                              v-model="model.hotel"
                />
            </v-flex>
        </v-layout>
    </f-panel>
</template>

<script>
    export default {
        name: 'app-lid-data-tourist',

        data() {
            return {
                hidden : 'Скрыто',

                countries : [
                    'Андорра',
                    'Объединенные Арабские Эмираты',
                    'Афганистан',
                    'Антигуа и Барбуда',
                    'Ангилья',
                    'Албания',
                    'Армения',
                    'Ангола',
                    'Антарктика',
                    'Аргентина',
                    'Восточное Самоа',
                    'Австрия',
                    'Австралия',
                    'Аруба',
                    'Азербайджан',
                    'Босния и Герцеговина',
                    'Барбадос',
                    'Бангладеш',
                    'Бельгия',
                    'Буркина-Фасо',
                    'Болгария',
                    'Бахрейн',
                    'Бурунди',
                    'Бенин',
                    'Сен-Бартелеми',
                    'Бермудские Острова',
                    'Бруней',
                    'Боливия',
                    'Бразилия',
                    'Багамские Острова',
                    'Бутан',
                    'Остров Буве',
                    'Ботсвана',
                    'Беларусь',
                    'Белиз',
                    'Канада',
                    'Кокосовые острова',
                    'Конго, Демократическая Республика',
                    'Центральноафриканская Республика',
                    'Конго, Республика',
                    'Швейцария',
                    'Кот-д’Ивуар',
                    'Острова Кука',
                    'Чили',
                    'Камерун',
                    'Китай',
                    'Колумбия',
                    'Коста-Рика',
                    'Куба',
                    'Кабо-Верде',
                    'Кюрасао',
                    'Остров Рождества',
                    'Кипр',
                    'Чешская Республика',
                    'Германия',
                    'Джибути',
                    'Дания',
                    'Доминика',
                    'Доминиканская Республика',
                    'Алжир',
                    'Эквадор',
                    'Эстония',
                    'Египет',
                    'Западная Сахара',
                    'Эритрея',
                    'Испания',
                    'Эфиопия',
                    'Финляндия',
                    'Фиджи',
                    'Фолклендские острова (Мальвинские острова)',
                    'Федеративные Штаты Микронезии',
                    'Фарерские острова',
                    'Франция',
                    'Метрополия Франции',
                    'Габон',
                    'Великобритания',
                    'Гренада',
                    'Грузия',
                    'Французская Гвиана',
                    'Гернси',
                    'Гана',
                    'Гибралтар',
                    'Гренландия',
                    'Гамбия',
                    'Гвинея',
                    'Гваделупа',
                    'Экваториальная Гвинея',
                    'Греция',
                    'Южная Георгия и Южные Сандвичевы острова',
                    'Гватемала',
                    'Гуам',
                    'Гвинея-Бисау',
                    'Гайана',
                    'Гонконг',
                    'Остров Херд и острова Макдональд',
                    'Гондурас',
                    'Хорватия',
                    'Гаити',
                    'Венгрия',
                    'Индонезия',
                    'Ирландия',
                    'Израиль',
                    'Остров Мэн',
                    'Индия',
                    'Британская территория в Индийском океане',
                    'Ирак',
                    'Иран',
                    'Исландия',
                    'Италия',
                    'Джерси',
                    'Ямайка',
                    'Иордания',
                    'Япония',
                    'Кения',
                    'Кыргызстан',
                    'Камбоджа',
                    'Кирибати',
                    'Коморские Острова',
                    'Сент-Китс и Невис',
                    'Корейская Народно-Демократическая Республика',
                    'Республика Корея',
                    'Кувейт',
                    'Каймановы острова',
                    'Казахстан',
                    'Лаос',
                    'Ливан',
                    'Сент-Люсия',
                    'Лихтенштейн',
                    'Шри-Ланка',
                    'Либерия',
                    'Лесото',
                    'Литва',
                    'Люксембург',
                    'Латвия',
                    'Ливия',
                    'Марокко',
                    'Монако',
                    'Молдова',
                    'Черногория',
                    'Сен-Мартен',
                    'Мадагаскар',
                    'Маршалловы острова',
                    'Македония',
                    'Мали',
                    'Мьянма',
                    'Монголия',
                    'Макао',
                    'Северные Марианские острова',
                    'Мартиника',
                    'Мавритания',
                    'Монтсеррат',
                    'Мальта',
                    'Маврикий',
                    'Мальдивы',
                    'Малави',
                    'Мексика',
                    'Малайзия',
                    'Мозамбик',
                    'Намибия',
                    'Новая Каледония',
                    'Нигер',
                    'Остров Норфолк',
                    'Нигерия',
                    'Никарагуа',
                    'Нидерланды',
                    'Норвегия',
                    'Непал',
                    'Науру',
                    'Ниуэ',
                    'Новая Зеландия',
                    'Оман',
                    'Панама',
                    'Перу',
                    'Французская Полинезия',
                    'Папуа – Новая Гвинея',
                    'Филиппины',
                    'Пакистан',
                    'Польша',
                    'Сен-Пьер и Микелон',
                    'Острова Питкэрн',
                    'Пуэрто-Рико',
                    'Сектор Газа',
                    'Западный берег реки Иордан',
                    'Португалия',
                    'Палау',
                    'Парагвай',
                    'Катар',
                    'Реюньон',
                    'Румыния',
                    'Сербия',
                    'Россия',
                    'Руанда',
                    'Саудовская Аравия',
                    'Соломоновы Острова',
                    'Сейшельские острова',
                    'Судан',
                    'Швеция',
                    'Сингапур',
                    'Острова Святой Елены, Вознесения и Тристан-да-Кунья',
                    'Словения',
                    'Шпицберген',
                    'Словакия',
                    'Сьерра-Леоне',
                    'Сан-Марино',
                    'Сенегал',
                    'Сомали',
                    'Суринам',
                    'Южный Судан',
                    'Сан-Томе и Принсипи',
                    'Сальвадор',
                    'Синт-Мартен',
                    'Сирия',
                    'Свазиленд',
                    'Теркс и Кайкос',
                    'Чад',
                    'Французские Южные и Антарктические территории',
                    'Того',
                    'Таиланд',
                    'Таджикистан',
                    'Токелау',
                    'Восточный Тимор',
                    'Туркменистан',
                    'Тунис',
                    'Тонга',
                    'Турция',
                    'Тринидад и Тобаго',
                    'Тувалу',
                    'Тайвань',
                    'Танзания',
                    'Украина',
                    'Уганда',
                    'Внешние малые острова США',
                    'Соединенные Штаты Америки',
                    'Уругвай',
                    'Узбекистан',
                    'Святой Престол (город-государство Ватикан)',
                    'Сент-Винсент и Гренадины',
                    'Венесуэла',
                    'Британские Виргинские острова',
                    'Виргинские острова',
                    'Вьетнам',
                    'Вануату',
                    'Уоллис и Футуна',
                    'Самоа',
                    'Косово',
                    'Йемен',
                    'Майотта',
                    'Южно-Африканская Республика',
                    'Замбия',
                    'Зимбабве'
                ]
            }
        },

        props : {
            model      : {},
            showClient : {},
            allowEdit  : {},
            box        : {}
        }
    }
</script>